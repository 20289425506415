import { LocationResponse } from "api/types";
import { AxiosError } from "axios";
import customAxios from "config";
import { globalJson } from "global/global_json";

const MAPS_KEY = globalJson?.mapKey;

const getHeaders = () => ({
  Accept: "application/json",
});

async function handleResponse<T>(response: Response): Promise<T> {
  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(
      errorData.message || errorData.detail || "Server responded with an error"
    );
  }
  const data: unknown = await response.json();
  // Here you might want to add runtime type checks depending on what T could be
  return data as T; // This assumes that the calling code knows what type it expects.
}

export const getLocationData = async (
  selectedCar?: number
): Promise<{
  data?: LocationResponse[];
  error?: string;
}> => {
  try {
    const baseUrl = "/api/company/locations/";
    const url =
      selectedCar !== undefined ? `${baseUrl}?fleet=${selectedCar}` : baseUrl;
    const response = await customAxios.get(url, {
      params: {
        company_id: globalJson.company_id,
      },
    });
    return response.data;
  } catch (error) {
    if (error instanceof AxiosError && error.response) {
      return {
        error:
          error.response.data.detail ||
          "An error occurred while fetching fleet data.",
      };
    }
    return { error: "An unknown error occurred." };
  }
};

export const searchLocations = async (input: string) => {
  const response = await fetch(
    `https://api-map.1now.app/toco_backend/google_maps_autocomplete/?input=${input}`,
    {
      headers: getHeaders(),
    }
  );
  return handleResponse(response);
};

export const getNearbyLocations = async (placeId: string) => {
  const response = await fetch(
    `https://api-map.1now.app/toco_backend/check_location_in_range/?place_id=${placeId}&key=${MAPS_KEY}`
  );
  return handleResponse(response);
};

export const getLocationFromLatLong = async (latLng: string) => {
  const response = await fetch(
    `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latLng}&sensor=true&key=${MAPS_KEY}`
  );
  return handleResponse(response);
};
